import { LOCALES } from "../locales";

const englishLocale = {
  [LOCALES.ENGLISH]: {
    hello: "Hi!",
    username: "Username",
    password: "Password",
    "sign in": "Sign in",
    login: "Login",
    "is required": "is required",
    exit: "Exit",
    dashboard: "Dashboard",
    users: "Users",
    total: "Total",
    page: "Page",
    show: "Show",
    records: "Records",
    firstname: "Firstname",
    lastname: "Lastname",
    of: "of",
    filters: "Filters",
    contains: "Contains",
    doesNotContain: "Does not contain",
    startsWith: "Starts with",
    endsWith: "Ends with",
    equals: "Equals",
    notEquals: "Not equals",
    greaterThan: "Greater than",
    greaterThanOrEqual: "Greater than or equal",
    lessThan: "Less than",
    lessThanOrEqual: "Less than or equal",
    after: "After",
    before: "Before",
    between: "Between",
    operator: "Operator",
    value: "Value",
    and: "and",
    home: "Home",
    "there was an error loading the menus":
      "There was an error loading the menus",
    settings: "Settings",
    profile: "Profile",
    "public avatar": "Public Avatar",
    "change anything related with your profile picture":
      "Change anything related with your profile picture",
    "upload avatar": "Upload avatar",
    "the maximum file size allowed is 200kb":
      "The maximum file size allowed is 200kb",
    "main settings": "Main Settings",
    "change anything related with your profile data":
      "Change anything related with your profile data",
    "enter your first name": "Enter your first name",
    "enter your last name": "Enter your last name",
    "enter your username, so people you know can recognize you":
      "Enter your username, so people you know can recognize you",
    "this email will be displayed on your public profile":
      "This email will be displayed on your public profile",
    update: "Update",
    theme: "Theme",
    language: "Language",
    "change to your prefered language": "Change to your prefered language",
    "change the main theme": "Change the main theme",
    email: "e-Mail",
    phone: "Phone",
    "stock administration": "Stock administration",
    "products administration": "Products administration",
    "tags administration": "Tags administration",
    "trademark administration": "Trademark administration",
    "categories administration": "Categories administration",
    "edit stock": "Edit zone",
    "new stock": "New zone",
    "edit product": "Edit product",
    "new product": "New product",
    "edit tag": "Edit tag",
    "new tag": "New tag",
    "new trademark": "New brand",
    "edit trademark": "Edit brand",
    "new category": "New category",
    "edit category": "Edit category",
    timezone: "Time Zone",
    "change timezone": "Change time zone",
    add: "Add",
    edit: "Edit",
    delete: "Delete",
    search: "Search",
    "this is your main password": "This is your main password",
    "*": "*",
    "there was an error loading the data":
      "There was an error loading the data",
    "there was an error loading the user data":
      "There was an error loading the user data",
    name: "Name",
    "change anything related with your permissions data":
      "Change anything related with your permissions data",
    "enter permission name": "Enter permission name",
    "Permission not found": "Permission not found",
    "User not found": "User not found",
    "change anything related with your roles data":
      "Change anything related with your roles data",
    "Role not found": "Role not found",
    "enter role name": "Enter role name",
    offset: "Offset",
    none: "None",
    order: "Order",
    label: "Label",
    father: "Father",
    parentMenu: "Parent menu",
    icon: "Icon",
    "menus administration": "Menus administration",
    action: "Action",
    alias: "Alias",
    file: "File",
    "file not selected": "File not selected",
    "choose file": "Choose file",
    cancel: "Cancel",
    confirm: "Confirm",
    "add menu": "Add menu",
    "edit menu": "Edit menu",
    change: "Change",
    remove: "Remove",
    "this menu does not have a resource assigned. Please contact administrator for assignment":
      "This menu does not have a resource assigned. Please contact administrator for assignment",
    "new role": "New role",
    "edit role": "Edit role",
    "new permission": "New permission",
    "edit permission": "Edit permission",
    "new application": "New application",
    "edit application": "Edit application",
    "new user": "New user",
    "edit user": "Edit user",
    categories: "Categories",
    products: "Products",
    tags: "Tags",
    trademark: "Trademark",
    status: "Status",
    navigate: "Navigate",
    "coming-soon": "Coming-soon",
    maintenance: "Maintenance",
    menus: "Menus",
    "change anything related with your permission data":
      "Change anything related with your permission data",
    refresh: "Refresh",
    "sorry, something went wrong. Try again.":
      "Sorry, something went wrong. Try again.",
    "menu title": "Menu title",
    "complete this field": "Complete this field",
    "selected columns": "Selected columns",
    "error loading data": "error loading data",
    column: "Column",
    "select the file to import": "Select the file to import",
    role: "Role",
    "permission settings": "Permission settings",
    "set the permissions you need on the role":
      "Set the permissions you need on the role",
    permissions: "Permissions",
    assigned: "Assigned",
    available: "Available",
    selected: "selected",
    "all items selected": "All items selected",
    "move selected left": "move selected left",
    "move selected right": "move selected right",
    roles: "Roles",
    demomap: "Demo map",
    "new resort": "New resort",
    "edit resort": "Edit resort",
    "change anything related with your resort data":
      "Change anything related with your permission data",
    "enter resort name": "Enter resort name",
    "enter resort email": "Enter resort email",
    "enter resort description": "Enter resort description",
    "enter resort address": "Enter resort address",
    "enter resort checkIn": "Enter resort checkIn",
    "enter resort checkOut": "Enter resort checkOut",
    "select city": "Select city",
    "enter resort geoLocation": "Enter resort geolocation",
    "enter resort privateMPKey": "Enter resort PRIVATE Mercado Pago key",
    "enter resort publicMPKey": "Enter resort PUBLIC Mercado Pago key",
    "geoLocation integration settings": "Geo location integration settings",
    "mercado pago integration settings": "Mercado Pago integration settings",
    "geoLocation integration settings description":
      "Change anything related with the Geo location integration",
    "mercado pago integration data settings description":
      "Change anything related with the Mercado Pago integration. Remember to write this information down in a safe place.",
    description: "Description",
    address: "Address",
    checkIn: "Check In",
    checkOut: "Check Out",
    city: "City",
    geoLocation: "Geo location",
    privateMPKey: "PRIVATE Mercado Pago key",
    publicMPKey: "PUBLIC Mercado Pago key",
    "new city": "New city",
    "edit city": "Edit city",
    "change anything related with your city data":
      "Change anything related with your city data",
    "enter city name": "Enter city name",
    "enter city description": "Enter city description",
    "enter city slug": "Enter city slug",
    slug: "Slug",
    cities: "Cities",
    "enter resort slug": "Enter resort slug",
    resorts: "Resorts",
    "change anything related with your amenity data":
      "Change anything related with your amenity data",
    "enter amenity name": "Enter amenity name",
    "new amenity": "New amenity",
    "edit amenity": "Edit amenity",
    amenities: "Amenities",
    "enter amenity icon":
      "Enter the icon that will represent this convenience. Please remember to choose an image the size of an icon approximately 25x25 px",
    validity: "Validity",
    enabled: "Enabled",
    promotions: "Promotions",
    url: "URL",
    "new brand": "New brand",
    "edit brand": "Edit brand",
    "brand image": "Brand image",
    "change anything related with the brand data":
      "Change anything related with the brand data",
    "enter the image that represents the brand":
      "Enter the image that represents the brand",
    "enter brand name": "Enter brand name",
    "enter brand description": "Enter brand description",
    "enter brand order": "Enter brand order",
    brands: "Brands",
    logo: "Logo",
    "change anything related with the category data":
      "Change anything related with the category data",
    "enter the image that represents the category":
      "Enter the image that represents the category",
    "enter category name": "Enter category name",
    "enter category slug": "Enter category slug",
    "enter category description": "Enter category description",
    "select parent category": "Select parent category",
    "category image": "Category image",
    parentCategory: "Parent category",
    image: "Image",
    "new characteristic": "New characteristic",
    "edit characteristic": "Edit characteristic",
    "change anything related with the characteristic data":
      "Change anything related with the characteristic data",
    "enter the icon that represents the characteristic":
      "Enter the icon that represents the characteristic",
    "characteristic icon": "Characteristic icon",
    "enter characteristic name": "Enter characteristic name",
    characteristics: "Characteristics",
    sku: "SKU",
    category: "Category",
    brand: "Brand",
    price: "Price",
    "change anything related with the product data":
      "Change anything related with the product data",
    "enter product sku": "Enter product sku",
    "enter product name": "Enter product name",
    "enter product description": "Enter product description",
    "enter product price": "Enter the base price of the product",
    "select product category": "Select product category",
    "select product brand": "Select product brand",
    "enter the images of the product. Please ensure that images do not exceed a resolution of 1920x1280":
      "Enter the images of the product. Please ensure that images do not exceed a resolution of 1920x1280",
    "product images": "Product images",
    gallery: "Gallery",
    "error uploading media": "There was an error while uploading the media",
    "parent category": "Parent category",
    "enter category order": "Enter category order",
    "new inventory": "New zone",
    "edit inventory": "Edit zone",
    "change anything related with your inventory data":
      "Change anything related with your zone data",
    "enter inventory name": "Enter zone name",
    "enter inventory description": "Enter zone description",
    inventory: "Zone",
    inventories: "Zones",
    "confirm delete": "Confirm delete",
    "resort images": "Resort images",
    "enter the images of the resort. Please ensure that images do not exceed a resolution of 1920x1280":
      "Enter the images of the resort. Please ensure that images do not exceed a resolution of 1920x1280",
    "select the resort the user belongs to if applicable":
      "Select the resort the user belongs to if applicable",
    resort: "Resort",
    repassword: "Repeat password",
    "enter the password again": "Enter the password again",
    "select the user role": "Select the user role",
    "amenities settings": "Amenities settings",
    "select the amenities your resort has":
      "Select the amenities your resort has",
    "assigned amenities": "Assigned amenities",
    "assign amenities from the list of available ones":
      "Assign amenities from the list of available ones",
    "if any comfort requires any extra information, it can be added to the following list":
      "If any comfort requires any extra information, it can be added to the following list",
    "extra data": "Extra data",
    "you need to assign a convenience to add its extra value":
      "You need to assign a convenience to add its extra value",
    "you do not have permissions to enter this menu":
      "You do not have permissions to enter this menu",
    "username input description":
      "Enter your username. It can be any alphanumatic combination, both with _ and -, have at least 4 characters and not exceed 25 characters",
    "invalid username": "Invalid username",
    "passwords differ": "Passwords differ",
    "password input description":
      "This is your main password. It can be any alphanumatic combination, both with _ and -, have at least 8 characters and not exceed 25 characters",
    "invalid password": "Invalid password",
    product: "Product",
    start: "Start",
    end: "End",
    priceschedules: "Price schedules",
    "enter priceSchedule price":
      "Enter the price of the product to be taken into account for the date range specified above",
    "enter priceSchedule end":
      "Enter the date up to which the price to be entered for the product is taken into account",
    "enter priceSchedule start":
      "Enter the date from which the price to be entered for the product is taken into account",
    "select priceSchedule product": "Select price schedule product",
    "change anything related with your priceSchedule data":
      "Change anything related with your priceSchedule data",
    "new priceSchedule": "New priceSchedule",
    "edit priceSchedule": "Edit priceSchedule",
    "Do you want to delete?": "Do you want to delete?",
    zone: "Zone",
    zones: "Zones",
    "change anything related with your password":
      "Change anything related with your password",
    "manage password": "Manage Password",
    "enter your new password": "Enter your new password",
    "enter your old password": "Enter your old password",
    "re-enter your new password": "Re-enter your new password",
    "old password": "Old Password",
    "inconsistent passwords": "You entered two diferent passwords",
    "Passwords do not match": "Your old password do not match",
    "successful password update": "Successful password update",
    services: "Services",
    "select the services that the resort has":
      "Select the services that the resort has",
    "online service": "Online service",
    "this service enables the resort to appear on the Alamar page":
      "This service enables the resort to appear on the Alamar page",
    "enable onlineService": "Enable online service",
    "booking service": "Booking service",
    "this service enables the booking system":
      "This service enables the booking system",
    "enable bookingSystem": "Enable booking system",
    online: "Online",
    identifier: "Identifier",
    productszone: "Products zone",
    instanceszone: "Instances zone",
    "new instance": "New instance",
    "edit instance": "Edit instance",
    "change anything related with the product instance data":
      "Change anything related with the product instance data",
    "select product type of the instance":
      "Select product type of the instance",
    "select the zone to which the instance belongs":
      "Select the zone to which the instance belongs",
    "enter the instance identifier": "Enter the instance identifier",
    "instance availability": "Instance availability",
    "changes instance availability in different contexts":
      "Changes instance availability in different contexts",
    "this enables the instance on the Alamar page":
      "This enables the instance on the Alamar page",
    "this enable/disabled the instance": "This enable/disabled the instance",
    "enable online instance": "Enable online instance",
    "enable instance": "Enable instance",
    "you must belong to a spa to add an instance":
      "you must belong to a spa to add an instance",
    "new booking": "New booking",
    "edit booking": "Edit booking",
    "reference data": "Reference data",
    "reference data of the person making the booking":
      "Reference data of the person making the booking",
    "at least one of these fields must be completed":
      "At least one of these fields must be completed",
    "booking dates": "Booking dates",
    "selection of the start and end date of the booking":
      "Selection of the start and end date of the booking",
    "details of the booking": "Details of the booking",
    "details of items being reserved": "Details of items being reserved",
    "payment record": "Payment record",
    "record of payments made": "Record of payments made",
    "enter the name of the person making the booking":
      "Enter the name of the person making the booking",
    "enter the document of the person making the booking":
      "Enter the document of the person making the booking",
    "enter the email of the person making the booking":
      "Enter the email of the person making the booking",
    "enter the phone of the person making the booking":
      "Enter the phone of the person making the booking",
    "select the user making the booking if applicable":
      "Select the user making the booking if applicable",
    user: "User",
    "start date": "Start date",
    "enter the start date of the booking":
      "Enter the start date of the booking",
    "end date": "End date",
    "enter the end date of the booking": "Enter the end date of the booking",
    paid: "Paid",
    "enter the amount that has been paid":
      "Enter the amount that has been paid",
    discount: "Discount",
    from: "From",
    to: "To",
    document: "Document",
    token: "Token",
    bookings: "Bookings",
    maps: "Maps",
    "available instances": "Available instances",
    "sidebar map instances description":
      "Drag and drop the instances on the map. By clicking on the instance on the map you can change its orientation",
    "map width": "Map width",
    "map height": "Map height",
    "save map": "Save map",
    "decoration elements": "Decoration elements",
    "decoration elements description":
      "Drag and place the decorative elements you want on the map. By clicking on the element on the map you can change its orientation",
    "there are no items to locate": "There are no items to locate",
    "map updated": "Map updated",
    editor: "Editor",
    source: "Source",
    "you can assign by selecting the zone and then entering the ID of the instance in that zone or transferring from the list below":
      "You can assign by selecting the zone and then entering the ID of the instance in that zone or transferring from the list below",
    "assigned instances": "Assigned instances",
    "the instance is already assigned or is not available":
      "The instance is already assigned or is not available",
    "instance not exist": "Instance not exist",
    totals: "Totals",
    "information on the total cost of the booking":
      "Information on the total cost of the booking",
    "discount calculated from the reservation":
      "Discount calculated from the reservation (it can be modified)",
    "total calculated from the reservation":
      "Total calculated from the reservation",
    "enter dates": "Enter dates",
    "select the reservation dates to be able to select the instances to reserve":
      "Select the reservation dates to be able to select the instances to reserve",
    "There is an price schedule overlap": "There is an price schedule overlap",
    "agenda created successfully": "agenda created successfully",
    "enable manual total": "Enable manual total",
    "enable manual discount": "Enable manual discount",
    "booking created successfully": "Booking created successfully",
    "remains to pay": "Remains to pay",
    view: "View",
    "personal data": "Personal data",
    booking: "Booking",
    reserved: "Reserved",
    "not available": "Not available",
    book: "Book",
    "search booking": "Search booking",
    "booking not found": "Booking not found",
    "agenda updated successfully": "Agenda updated successfully",
    "could not get weather": "Could not get weather",
    "new password": "New password",
    "invalid email": "Invalid email",
    "password changed successfully": "Password changed successfully",
    send: "Send",
    "you must belong to a resort to see the map":
      "You must belong to a resort to see the map",
    "count days": "Count days",
    "please enter a valid date range": "Please enter a valid date range",
    "enter reservation token, name, document or email":
      "Enter token, name, document or email",
    "new promotion": "New promotion",
    "edit promotion": "Edit promotion",
    "change anything related with your promotion data":
      "Change anything related with your promotion data",
    "enter promotion name": "Enter promotion name",
    "enter promotion description": "Enter promotion description",
    "enter promotion validityFrom": "Enter promotion validity from",
    "enter promotion validityTo": "Enter promotion validity to",
    "enter promotion url": "Enter promotion url",
    "this enable/disabled the promotion": "This enable/disabled the promotion",
    "enable promotion": "Enable promotion",
    validityFrom: "Validity from",
    validityTo: "Validity to",
    "validity settings": "Validity settings",
    "change everything related to the validity of the promotion":
      "Change everything related to the validity of the promotion",
    "parameters settings": "Parameters settings",
    "configure promotion parameters":
      "Configure the parameters that will activate the promotion",
    "promotions parameters": "Promotions parameters",
    "add promotions parameters": "Add promotions parameters",
    productsQuantity: "Products quantity",
    daysQuantity: "Days quantity",
    greaterDaysQuantity: "Greater than number of days",
    dateRanges: "Date ranges",
    coupon: "Coupon",
    "add parameter": "Add parameter",
    "selected products": "Selected products",
    "quantity products": "Quantity products",
    "quantity days": "Quantity days",
    "greater quantity days": "Greater quantity days",
    "price modifier settings": "Price modifier settings",
    "configure price modifier":
      "Configure how the price will be modified according to the promotion",
    "promotions price modifier": "Promotions price modifier",
    "select price modifier": "Select price modifier",
    DirectDiscountInMoney: "Direct discount in money",
    DirectDiscountInPercentage: "Direct discount in percentage",
    TotalDirectValue: "Total direct value",
    DirectValuePerDay: "Direct value per day",
    amount: "Amount",
    percentage: "Percentage",
    "the parameters and the price modifier must be configured":
      "The parameters and the price modifier must be configured",
    "the date range must be selected": "The date range must be selected",
    "promotion updated successfully": "Promotion updated successfully",
    "promotion created successfully": "Promotion created successfully",
    resume: "Resume",
    loading: "Loading",
    "a zone must be selected": "A zone must be selected",
    "matching promotions per instance": "Matching promotions per instance",
    "charge payment": "Charge payment",
    "new payment": "New payment",
    "payment changed successfully": "Payment changed successfully",
    "new amount paid": "New amount paid",
    "enter valid amount": "Enter valid amount",
    "associated categories": "Associated categories",
    "amenity.config.categories.description":
      "Determine which categories comfort is associated with",
    "hold down the Ctrl button to select multiple options":
      "Hold down the Ctrl button to select multiple options",
    "searcheable columns": "Searcheable columns:",
    "enter the city of origin of the person making the booking": "Enter the city of origin of the person making the booking",
    "active": "Active",
    "change anything related with your season data": "Change anything related with your season data",
    "new season": "New season",
    "enter season name": "Enter season name",
    "enter season description": "Enter season description",
    "enter season start": "Enter season start",
    "enter season end": "Enter season end",
    "this enables the season (only one season can be enabled)": "This enables the season (only one season can be enabled)",
    "enable season": "Enable season",
    "There is already an active season": "There is already an active season",
    "There is overlapping of season": "There is overlapping of season",
    "season created successfully": "Season created successfully",
    "initial identifier": "Initial identifier",
    "enter the initial instance identifier": "Enter the initial identifier",
    "quantity identifiers": "Quantity identifiers",
    "enter quantity identifiers": "Enter quantity identifiers",
    "create massive instances": "Create massive instances",
    "Error creating massive instances": "Error creating massive instances",
    "instances created": "Instances created",
    "instances omitted": "Instances omitted",
    "add massive": "Add massive",
    "user settings": "User settings",
    "change anything related with your user profile data": "Change anything related with your user profile data",
    "user profile": "User profile",
    "resort profile": "Resort profile",
  },
};

export default englishLocale;
